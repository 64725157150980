import React, { Fragment } from "react";
import { ThemeProvider } from "styled-components";
import Layout from "../containers/Home/Layout";
import { interiorTheme } from "common/theme/interior";

import "common/assets/css/main-page.css";

import Seo from "components/seo";

import TitleBar2 from "common/components/Titlebar2";
import SingleServicePage from "../containers/Home/pages.style";
import ServiceSectionTwo from "../containers/Sections/ServiceSectionTwo";
import ServiceSectionThree from "../containers/Sections/ServiceSectionThree";
import ServiceSectionOne from "../containers/Sections/ServiceSectionOne";
import ServiceSectionFour from "../containers/Sections/ServiceSectionFour";

const HairBondingforMen = () => {

  const seoData = {
    title: 'Hair Bonding for Men in Delhi - Radiance Hair Studio',
    description: 'Discover the most innovative hair bonding for men in Delhi at Radiance Hair Studio. Experience a unique and advanced method for natural-looking results. ',
    keywords: ['hair bonding for men in Delhi'],
  };

  const schemaData = {
    "@context": "https://schema.org",
    "@type": "Service",
    "@id": "Service",
    "alternateName": "Radiance Hair Studio",
    "areaServed": "New Delhi",
    "image": [
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/service-images/Hair-Bonding-for-Men's-copy.jpg",
      "https://www.radiancehairstudio.com/hair-bonding-for-men1.png",
      "https://www.radiancehairstudio.com/hair-bonding-for-men2.png",
      "https://www.radiancehairstudio.com/help-image-1.png",
      "https://www.radiancehairstudio.com/home/home-image-18.webp"
    ],
    "logo": "https://www.radiancehairstudio.com/static/radiance-logo-721b0cd4def3d400c04ba899afd38810.png",
    "disambiguatingDescription": "hair bonding for men in Delhi",
    "additionalType": "https://www.radiancehairstudio.com/hair-bonding-for-men.php",
    "category": "https://www.radiancehairstudio.com/hair-bonding-for-men.php",
    "description": "Discover the most innovative hair bonding for men in Delhi at Radiance Hair Studio. Experience a unique and advanced method for natural-looking results.",
    "mainEntityOfPage": "https://www.radiancehairstudio.com/hair-bonding-for-men.php",
    "serviceType": "Hair Bonding for Men's"
  };
  
  const sectionS1 = [
    {
      title: "Hair Patch for Men",
      img: "/hair-patch-for-men.png",
    },
    {
      title: "Human Hair Extension",
      img: "/7.png",
    },
    {
      title: "Hair Weaving",
      img: "/8.png",
    },
    {
      title: "Non-Surgical Hair Replacement",
      img: "/9.png  ",
    }
  ];

  const sectionS2 = [
    {
      img: "/service-images/Hair-Bonding-for-Men's-copy.jpg",
      content: (
        <div>
          <h2>Hair Bonding for Men's</h2>
          <p>
          Are you looking to adopt the latest hairstyle? There is no need to get through any
          treatment plans.Getting your hair bonded in Delhi is the simplest way to accomplish this. This will not only give you a better look, but you are going to be a style icon in your city. 
          </p>
          <h2>Radiance Trends</h2>
          {/* <p>
          <a href="/" style={{ color: "#373737"}}>Radiance</a> Hair Salon is ready to give you a wide range of style in this
            aspect. Try out a new looking every alternate day and start stunning
            your colleagues and friends. Hair bonding is going to open up a new
            horizon to your stylish life.
          </p> */}
          <p><a href="/" style={{ color: "#373737"}}>Radiance</a> Hair Salon is ready to give you a wide range of style in this aspect. Start impressing your friends and coworkers by trying a fresh appearance every other day. Hair bonding is going to open up a new horizon to your stylish life. </p>
          <p>
          Many do think that hair bonding is a hair loss treatment plan, but the reality is not on the same track. Hair bonding in Delhi involves cutting your natural hair and applying adhesive to the skull, where stylistic hair is easily taped or affixed. Are you starting to worry about getting fake hair on your head? Please don’t be. Because here we provide our customers with 100% natural human hair extensions exclusively designed with individual care. Our hair professionals will always be available to help you with any questions you may have regarding hair issues and will direct you in the right direction.
          </p>
          <p>
            Additionally, we will be offering you a superb portfolio of bonding types at Radiance. This will not only give you a stunning beauty but will also be a safe journey, as there is no side-effect from the entire thing. Medical science has given the adhesive we use its full approval and certification.Our professionals are also perfectly trained to support you in the entire matter. 
          </p>
          Before experiencing this amazing transformation, it’s important to understand the benefits of hair bonding, which we’ve outlined below.

        </div>
      ),
    },
  ];
  
  const sectionS3 = [
    {
      smallImg: "/hair-bonding-for-men1.png",
      largeImg: "/hair-bonding-for-men2.png",
      content: (
        <div>
          <h2>Advantages of Hair Bonding in Delhi</h2>
  
          <ul>
            <li>
            If you're looking for a fresh new hairstyle, hair bonding is an excellent option. It allows you to change your look effortlessly and without any hassle. So, why wait any longer?
            </li>
            <li>
            One of the key benefits of hair bonding is that it does not harm your natural hair. The procedure involves applying a specialized adhesive to the scalp, ensuring a secure hold without causing damage. This is one of the reasons why many television personalities opt for this method.
            </li>
            <li>
            The adhesive used in the process meets international quality standards, making it completely safe and free from side effects. There is no risk involved, making it a reliable and convenient hair enhancement solution.
            </li>
            <li>
            Since hair bonding is a non-invasive procedure, it is completely painless and does not cause any discomfort. You can enjoy a new look instantly and even experiment with different hair colors to suit your style.
            </li>
            <li>
            If you’re ready for a transformation, hair bonding in Delhi offers a seamless way to achieve your desired look with ease!
            </li>
          </ul>
        </div>
      ),
    },
  ];
  
  const sectionS4 = [
    {
      title: "FAQs of Hair Bonding",
      description:
        "We understand that you may have several questions about hair bonding. Here are some of the most common ones, along with their answers, to help you make an informed decision.",
      description2: "Looking for expert hair bonding services? Visit Radiance today for a personalized consultation!",
      faq: [
        {
          title: "Will hair bonding cause hair loss?",
          description:
            "No, hair bonding does not lead to hair loss. The process involves applying an adhesive to the scalp, not directly to your natural hair. Since it does not interfere with hair roots, there is no risk of hair damage or shedding.",
        },
        {
          title: "How often can I change my hairstyle?",
          description:
            "You can switch up your look as often as you like—even daily! Hair bonding offers great flexibility, allowing you to experiment with different patches and hair colors without any restrictions.",
        },
        {
          title: "Does hair bonding have any side effects on the scalp?",
          description:
            "Not at all! The adhesive used for hair bonding at Radiance meets international safety standards. Our skilled professionals ensure that the process is safe, minimizing any risk of irritation or side effects.",
        },
        {
          title:
            "Can I get hair bonding even if I have natural hair?",
          description:
            "Absolutely! Hair bonding is applied directly to the scalp, meaning your natural hair remains unaffected. If needed, a slight trim may be done to blend the look seamlessly.",
        },
      ],
    },
  ];
  

  return (
    <ThemeProvider theme={interiorTheme}>
      <Fragment>
      <Seo {...seoData} schemaData={schemaData} />
        <Layout>
          <TitleBar2 title="Hair Bonding for Men's" banner="/hair-bonding-for-men-banner.png"/>
          <SingleServicePage>
            <ServiceSectionOne data={sectionS1} title="Hair Bonding for Men's" />
            <ServiceSectionTwo data={sectionS2} />
            <ServiceSectionThree data={sectionS3} />
            <ServiceSectionFour data={sectionS4} />
          </SingleServicePage>
        </Layout>
      </Fragment>
    </ThemeProvider>
  );
};
export default HairBondingforMen;
